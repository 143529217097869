<template>
<Page id="users" :title="username">
      <b-row class="mb-3">
            <b-col cols="12" md="6">
                  <b-row no-gutters class="">
                        <b-col cols="12" md="6" class="mb-2">
                              <b-skeleton-img v-if="!ready"></b-skeleton-img>
                              <b-img class="w-75" center rounded="circle" v-else :src="avatar"></b-img>
                        </b-col>
                        <b-col cols="12" md="6" v-if="ready">
                              <b-row no-gutters class="mb-1 align-items-center">
                                    <b-col class="font-weight-bold">Namn</b-col>
                                    <b-col>{{username}}</b-col>
                              </b-row>
                              <b-row no-gutters class="mb-1 align-items-center">
                                    <b-col class="font-weight-bold">Rank</b-col>
                                    <b-col class="text-white p-2" :class="rankclass()">{{ranktext()}}</b-col>
                              </b-row>
                              <b-row no-gutters class="mb-1 align-items-center">
                                    <b-col class="font-weight-bold">Ålder</b-col>
                                    <b-col>{{age}}</b-col>
                              </b-row>
                        </b-col>
                        <b-col v-else cols="12" md="6" class="mb-2">
                              <b-skeleton-table :rows="3" :columns="2"></b-skeleton-table>
                        </b-col>
                  </b-row>

            </b-col>
            <b-col cols="12" md="6">
                  <div v-if="!ready">
                        <p v-for="n in 4" :key="n">
                              <b-skeleton animation="wave" width="85%"></b-skeleton>
                        </p>
                  </div>
                  <div v-else>
                        <p>
                              Användaren <span class="camel-case">{{ username }}</span> har deltagit i
                              {{ tournaments.length }} turneringar.</p>
                        <p v-if="totalrank() < 0.35">
                              <span class="camel-case">{{username}}</span> tillhör det absoluta toppskiktet när det kommer till tipsare!
                        </p>
                        <p v-else-if="totalrank() < 0.65">
                              <span class="camel-case">{{username}}</span> är en medelbra tipsare som ofta hamnar runt mitten.
                        </p>
                        <p v-else>
                              <span class="camel-case">{{username}}</span> är kanske inte den bästa tipsare men är desta mer hängiven!
                        </p>
                        <p>
                              <span class="camel-case">{{username}}</span> har vunnit {{winner}} av sina {{tournaments.length}} turneringar.
                        </p>
                        <p>
                              Klicka på respektive turnering
                              för att se detaljerad statistik.
                        </p>
                  </div>
            </b-col>
      </b-row>
      <b-skeleton-table v-if="loading" :rows="3" :columns="4"></b-skeleton-table>
      <b-row @click="ShowTournamentStats(t)" v-else v-for="t in tournaments" :key="t.id" class="d-flex align-items-center tournament tournament-list justify-content-center">
            <b-col cols="6" md="2" lg="1">
                  <b-img class="icon" :src="tournamentImage(t)"></b-img>
            </b-col>
            <b-col cols="6" md="4" lg="5">
                  <h4>{{t.name}}</h4>
                  <p class="font-italic d-none d-sm-block">{{description(t)}}</p>
            </b-col>
            <b-col cols="6" md="3" class="align-self-stretch mt-2 mt-md-0">
                  <div class="h-50 bg-dark text-white center py-1 py-md-0">
                        {{sport(t)}}
                  </div>
                  <div :class="classnames(active(t))" class="h-50 center py-1 py-md-0">
                        {{active(t).text}}
                  </div>
            </b-col>
            <b-col cols="6" md="3" :class="placementclass(t)" class="d-flex align-items-center justify-content-center align-self-stretch mt-2 mt-md-0 text-white">
                  <span v-if="placement(t)">{{placement(t)}} av {{totalusers(t)}}</span>
                  <span v-else>Ej betalande</span>
            </b-col>
            <b-col cols="12">
                  <UTStats :username="username" :tournament="t" ref="statsitem"></UTStats>
            </b-col>
      </b-row>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");
const UTStats = () => import("@/components/users/UserTournamentStats");

import tournamentsenum from "@/helpers/enums/tournaments";

export default {
      name: "User",
      components: {
            Alert,
            Page,
            Loader,
            UTStats
      },
      data() {
            return {
                  ready: false,
                  username: "",
                  placements: {},
                  tstats: {

                  }
            };
      },
      metaInfo() {
            return {
                  title: this.username,
                  meta: [{
                        name: "description",
                        content: "Ändra dina uppgifter!",
                  }, ],
            };
      },
      computed: {
            loading() {
                  return this.$store.getters["profileg/userLoading"];
            },
            _userdata() {
                  var user = this.$store.getters["profileg/user"];
                  if (user)
                        return user;
                  return {
                        user: {},
                        tournaments: {},
                        tournamentstats: {}
                  };
            },
            age() {
                  var user = this.user;
                  if (user)
                        return user.age;
                  return "Ej angivet";
            },
            user() {
                  return this._userdata.user;
            },
            stats() {
                  return this._userdata.stats;
            },
            tournaments() {
                  var list = [];
                  var t = this._userdata.tournaments;
                  if (t) {
                        for (var id in t) {
                              list.push(t[id]);
                        }
                  }
                  return this.$functions.sort(list, "startdate", true);
            },
            avatar() {
                  return this.$images.userAvatar(this.user.id, this.user.imgversion);
            },
            winner() {
                  var nr = 0;
                  for (var id in this.placements) {
                        if (this.placements[id] == 1)
                              nr++;
                  }
                  return nr;
            }
      },
      methods: {
            status(tournament) {
                  return tournamentsenum.status[tournament.status];
            },
            active(tournament) {
                  return tournamentsenum.getTournamentActiveItem(tournament.active);
            },
            tournamentImage(tournament) {
                  return this.$images.tournamentImage(tournament.uuid, tournament.imgversion);
            },
            sport(tournament) {
                  return tournamentsenum.tournamentSport(tournament.sport);
            },
            description(tournament) {
                  return tournament.description;
            },
            classnames(item) {
                  return "text-" + item.txt + " bg-" + item.bg;
            },
            totalusers(tournament) {
                  var tstats = this.stats;
                  if (tstats[tournament.id]) {
                        var stats = tstats[tournament.id];

                        return stats.length;
                  }
                  return 0;
            },
            fixPlacements() {
                  var tstats = this.stats;
                  for (var id in tstats) {
                        var stats = tstats[id];

                        var placements = this.$functions.fixPlacements(
                              stats,
                              "totalpoints",
                              "placement"
                        );
                        this.placements[id] = 0;
                        for (var i = 0; i < placements.length; i++)
                              if (placements[i].userid == this.user.id) {
                                    this.placements[id] = placements[i].placement;
                                    break;
                              }
                  }
            },
            placement(tournament) {
                  if (this.placements && this.placements[tournament.id])
                        return this.placements[tournament.id];
                  return false;
            },
            rank(t) {
                  var pl = 100;
                  var place = this.placement(t);
                  var total = this.totalusers(t);
                  if (place && total) {
                        pl = place / total;
                  }
                  return pl;
            },
            totalrank() {
                  var pl = 100;
                  var place = 0;
                  var total = 0;
                  var list = this.tournaments;
                  for (var i = 0; i < list.length; i++) {
                        var t = list[i];
                        place += this.placement(t);
                        total += this.totalusers(t);
                  }
                  if (place && total) {
                        pl = place / total;
                  }
                  return pl;

            },
            placementclass(t) {
                  var rank = this.rank(t);
                  return this._rankclass(rank);
            },
            rankclass() {
                  var rank = this.totalrank();
                  return this._rankclass(rank);
            },
            _rankclass(rank) {
                  if (rank <= 0.35) // toop 35%
                        return "bg-success";
                  else if (rank <= 0.65)
                        return "bg-warning";
                  else return "bg-danger";
            },
            ranktext() {
                  var rank = this.totalrank();
                  if (rank <= 0.35) // toop 35%
                        return "Topptipsare!";
                  else if (rank <= 0.65)
                        return "Medeltipsare";
                  else return "Novistipsare";
            },
            ShowTournamentStats(tournament) {
                  var refs = this.$refs["statsitem"];
                  for (var i = 0; i < refs.length; i++) {
                        var tid = refs[i].GetTournamentId();
                        if (tid == tournament.id) {
                              refs[i].ShowStats();
                              break;
                        }
                  }
            }
      },
      mounted() {
            this.username = this.$route.params["username"];
            this.$store.dispatch("profileg/getUser", this.username).then(() => {
                  this.fixPlacements();
                  this.ready = true;
            });
      },
};
</script>
